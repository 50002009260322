import { ReactElement } from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { TrackButton } from 'components/TrackButton';
import { TrackingEvent } from 'lib/tracking/events';

const Wrapper = styled(Container)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;

  /* 145px is the height from the header + footer */

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: calc(100vh - 145px);
  }

  Button {
    min-height: ${(props) => props.theme.spacing(6)};
    padding: ${(props) => props.theme.spacing(1.5, 7.5)};
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
      margin-bottom: ${(props) => props.theme.spacing(6.25)};
    }
  }
`;

const Headline = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
`;

const Image = styled('img')`
  margin: 40px auto;
  max-width: 100%;
`;

const Text = styled(Typography)`
  margin: ${(props) => props.theme.spacing(1.25, 0, 6.25)};

  ${(props) => props.theme.breakpoints.down('md')} {
    margin: ${(props) => props.theme.spacing(1.25, 0, 3.75)};
  }
`;

export interface SomethingWentWrong {
  headline: string;
  children: ReactElement | string;
}

export default function SomethingWentWrong({
  headline,
  children,
}: SomethingWentWrong) {
  return (
    <Wrapper>
      <Image
        src="/assets/visuals/404_error_illu.svg"
        alt="Fehler Icon"
        width="648"
        height="264"
      />
      <Headline variant="h3">{headline}</Headline>
      <Text variant="body1">{children}</Text>
      <Link href="/kreditvergleich">
        <TrackButton trackingEvent={TrackingEvent.ERRORPAGE_HOMEBUTTON_ONINTERACT} variant="contained">Zum Kreditvergleich</TrackButton>
      </Link>
    </Wrapper>
  );
}
