import { MetaHead } from 'components/MetaHead/MetaHead';
import { HeaderWithOptions } from 'components/Header';
import { Footer } from 'components/Footer';
import SomethingWentWrong from 'components/SomethingWentWrong';

export default function Custom404() {
  return (
    <>
      <MetaHead
        title="Seite nicht vorhanden - smava.de"
        follow={false}
        index={false}
      />

      <HeaderWithOptions hasLoginLink hasNavigationMenu hasPhoneBox={false} />
      <SomethingWentWrong headline="Es tut uns leid, die von Ihnen gesuchte Seite gibt es nicht">
        Wir helfen Ihnen gerne, günstige Kredite für Ihre Wünsche zu finden.
      </SomethingWentWrong>
      <Footer />
    </>
  );
}
